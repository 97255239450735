import { Component } from '@angular/core';
import { LoadingService } from './core/services/loading.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})
export class AppComponent {
  router: any
  constructor(public loadingService: LoadingService, private route: Router) {
    this.router = this.route
  }
  title = 'procede-fe';
}
