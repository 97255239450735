import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { filter } from 'rxjs';
import { AuthService } from 'src/app/features/auth/services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class SidebarComponent implements OnInit {
  activeroute: any;
  userData: any;

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.loadUserData();

    this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd)
    )
    .subscribe(() => {
      this.activeroute = this.router.url;
    });
}


  loadUserData(){
    const tokenInfo = localStorage.getItem('accessToken');
    const name = localStorage.getItem('name');
    this.userData = name
  }

  logout(){
    this.authService.logout();
    this.router.navigate(['/auth/login']);
  }

}
