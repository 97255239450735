<div class="custom-spinner" *ngIf="loadingService.loading$ | async">
  <p-progressSpinner
    styleClass="custom-spinner"
    strokeWidth="4"
    fill="#EEEEEE"
    animationDuration=".5s"
  ></p-progressSpinner>
</div>

<div class="">
  <app-sidebar class="col-4" *ngIf="router.url !== '/auth/login' && router.url !== '/auth/register'" /> 
  <router-outlet class="col-8"></router-outlet>
</div>
