import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ATResponse, LoginFormDTO } from '../models/login.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private accessTokenSubject: BehaviorSubject<string | null>;
  public accessToken: Observable<string | null>;

  constructor(private http: HttpClient) {
    this.accessTokenSubject = new BehaviorSubject<string | null>(localStorage.getItem('accessToken'));
    this.accessToken = this.accessTokenSubject.asObservable();
   }


  setAccessToken(token: string): void {
    localStorage.setItem('accessToken', token);
    this.accessTokenSubject.next(this.getAccessToken());
  }

  getAccessToken(): string | null {
    return localStorage.getItem('accessToken');
  }

  isAuthenticated(): boolean {
    return !!this.getAccessToken()
  }

  login(data: LoginFormDTO) {
    return this.http.post<ATResponse>(`${environment.BASE_URL}/login`, data)
  }

  logout(){
    localStorage.removeItem('accessToken');
    this.accessTokenSubject.next(null)
  }

  register(data: any) {
    return this.http.post<any>(`${environment.BASE_URL}/users/register`, data)
  }
}
