<div
  class="d-flex flex-column flex-shrink-0 p-3 bg-sidebar"
  style="
    width: 280px;
    height: 100vh;
    position: fixed;
    box-shadow: 1px 0px 10px 0px rgba(0, 0, 0, 0.5);
  "
>
  <a href="/"
    class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none"
  >  <img src="assets/image/procede.png" alt=""></a>

  <div class="d-flex align-items-center mx-3 my-4 sidebar-item">
    <i class="fa-regular fa-circle-user sidebar-icon"></i>
    <span class="ms-2 sidebar-text">{{ userData }}</span>
  </div>

  <hr/>
  <ul class="nav nav-pills flex-column mb-auto">
    <li class="nav-item" style="display: none;">
      <a [ngClass]="{'active': activeroute === '/dash', 'link-white': activeroute !== '/dash'}" routerLink="/" class="nav-link sidebar-item" aria-current="page">
        <i class="fa-solid fa-table sidebar-icon"></i> 
        <span class="sidebar-text">Dashboard</span>
      </a>
    </li>
    <li>
      <a [ngClass]="{'active': activeroute === '/leads', 'link-white': activeroute !== '/leads'}" routerLink="/leads" class="nav-link sidebar-item">
        <i class="fa-regular fa-folder-open sidebar-icon"></i>
        <span class="sidebar-text">Leads</span>
      </a>
    </li>
    <li>
      <a [ngClass]="{'active': activeroute === '/in-progress', 'link-white': activeroute !== '/in-progress'}" routerLink="/in-progress" class="nav-link sidebar-item">
        <i class="fa-solid fa-phone-volume sidebar-icon"></i>
        <span class="sidebar-text">Em Andamento</span>
      </a>
    </li>
    <li>
      <a [ngClass]="{'active': activeroute === '/recycling', 'link-white': activeroute !== '/recycling'}" routerLink="/recycling" class="nav-link sidebar-item">
        <i class="fa-solid fa-arrows-rotate sidebar-icon"></i>
        <span class="sidebar-text">Finalizados</span>
      </a>
    </li>
    <li>
      <a [ngClass]="{'active': activeroute === '/process-search', 'link-white': activeroute !== '/process-search'}" routerLink="/process-search" class="nav-link sidebar-item">
        <i class="fa-solid fa-search sidebar-icon"></i>
        <span class="sidebar-text">Busca</span>
      </a>
    </li>
  </ul>
  <hr />
  <div class="dropdown">
    <div
      class="d-flex align-items-center justify-content-between link-white text-decoration-none"
    >
      <div class="ms-3" (click)="logout()">
        <i
          class="fa-solid fa-right-from-bracket"
          style="font-size: 20px; cursor: pointer; color: #fff; margin-right: 10px;"
        ></i> <span>LOGOUT</span>
      </div>
    </div>
  </div>
</div>
