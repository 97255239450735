import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';

const routes: Routes = [
  {
    path: 'leads',
    loadChildren: () =>
      import('./features/leads/leads.module').then((m) => m.LeadsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'process-details',
    loadChildren: () =>
      import('./features/process-details/process-details.module').then((m) => m.ProcessDetailsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'in-progress',
    loadChildren: () =>
      import('./features/in-progress/in-progress.module').then((m) => m.InProgressModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'recycling',
    loadChildren: () =>
      import('./features/recycling/recycling.module').then((m) => m.RecyclingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'fechamento-contrato',
    loadChildren: () =>
      import('./features/fechamento-contrato/fechamento-contrato.module').then((m) => m.FechamentoContratoModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'process-search',
    loadChildren: () =>
      import('./features/process-search/process-search.module').then((m) => m.ProcessSearchModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/auth/auth.module').then((m) => m.AuthModule),
  },
  { path: '', redirectTo: 'leads', pathMatch: 'full' },
  { path: '**', redirectTo: 'leads', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
